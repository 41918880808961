import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  direction?: 'L' | 'R'
  onClick?: (e: any) => void
  variant?: Variant
}

export const Arrow = memo(function Arrow({
  direction = 'L',
  onClick,
  variant = 'default',
}: Props) {
  return (
    <Container dial={5} onClick={onClick} variant={variant}>
      {direction === 'L' ? <ChevronLeft /> : <ChevronRight />}
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  width: 3.75rem;
  height: 3.75rem;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  transform: rotate(45deg);
  position: relative;
  transition: 0.3s ease-in-out;
  &:first-of-type {
    margin-right: 1.5rem;
  }
  &:hover {
    svg {
      opacity: 0.5;
    }
  }

  svg {
    width: auto;
    height: 0.75rem;
    fill: none;
    position: relative;
    fill: ${({ theme }) => theme.colors.variants.primaryDark};
    stroke-width: 0.5;
    transform: rotate(-45deg);
    transition: 0.2s ease-in-out;
  }

  ${({ theme, variant }) => {
    switch (variant) {
      case 'invert':
        return css`
          border-color: ${theme.colors.variants.neutralLight4};
          &:before {
            background: ${theme.colors.variants.neutralLight4};
          }
          &:hover {
            svg {
              stroke: ${theme.colors.variants.neutralDark1};
            }
          }

          svg {
            stroke: ${theme.colors.variants.neutralLight4};
          }
        `
    }
  }}

  @media (max-width: 1023px) {
    width: 3.125rem;
    height: 3.125rem;

    &:first-of-type {
      margin-right: 1.25rem;
    }
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'invert'
