import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  clickedSlide?: number
  images: ImageProps[]
  isOpen?: boolean
  siteName?: string
}

export const Gallery = memo(function Gallery({
  clickedSlide,
  images,
  isOpen,
  siteName,
}: Props) {
  if (!images) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1200,
    },
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
  })

  const [nextImageIndex, setNextImageIndex] = useState<number>(1)

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    if (clickedSlide !== undefined) {
      galleryRef.current?.moveToIdx(clickedSlide, false, { duration: 0 })
    }

    setLoaded(newLoaded)

    if (isOpen) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 37:
            galleryRef.current?.prev()
            break
          case 39:
            galleryRef.current?.next()
            break
        }
      })
    }

    if (currentSlide === nextImageIndex) {
      const newLoaded = [...loaded]
      newLoaded[nextImageIndex] = true
      setLoaded(newLoaded)

      const newIndex = nextImageIndex + 1
      setNextImageIndex(newIndex >= images.length ? 0 : newIndex)
    }
  }, [clickedSlide, currentSlide, galleryRef, sliderRef, images.length])

  return (
    <Container>
      {siteName ? <SiteName>{siteName}</SiteName> : null}

      <Carousel ref={sliderRef}>
        {uniq(images).map((item, index) => (
          <Slide key={index} className="keen-slider__slide">
            <Inner style={positionStyle(index)}>
              {loaded[index] ? (
                <Image media="(min-width: 2800px)" {...item} />
              ) : (
                <Spinner />
              )}
            </Inner>
          </Slide>
        ))}
      </Carousel>

      {images && images.length > 1 ? (
        <Arrows row>
          <Arrow
            onClick={(e) => e.stopPropagation() || galleryRef.current?.prev()}
          />
          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || galleryRef.current?.next()}
          />
        </Arrows>
      ) : null}

      <Counter>{`${currentSlide + 1}/${images.length}`}</Counter>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  &:after {
    content: '';
    width: 100%;
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    z-index: 1;
  }
  &:after {
    height: 33.333vh;
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark, 0)},
      ${rgba(theme.colors.variants.primaryDark, 0.7)}
    );
    bottom: 0;
  }
`

const Carousel = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  outline: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 1199px) {
  }

  @media (max-width: 767px) {
    width: 100%;
    top: 0;
    left: 0;
  }
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const SiteName = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.125rem;
  position: absolute;
  z-index: 2;
  bottom: 4.375rem;
  left: 2.917vw;

  @media (max-width: 1023px) {
    left: 1.875rem;
    bottom: 4rem;
  }

  @media (max-width: 574px) {
    bottom: 3rem;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3rem;

  @media (max-width: 767px) {
    bottom: 3rem;
  }
  @media (max-width: 574px) {
    right: 1.875rem;
    left: auto;
    bottom: 1.875rem;
    transform: none;
  }
`

const Counter = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.125rem;
  position: absolute;
  z-index: 2;
  right: 2.917vw;
  bottom: 4.375rem;

  @media (max-width: 1023px) {
    right: 1.875rem;
    bottom: 4rem;
  }
  @media (max-width: 574px) {
    display: none;
  }
`
