import styled from '@emotion/styled'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  backgroundImage?: string
}

export const Visual = memo(function Visual({ backgroundImage }: Props) {
  if (!backgroundImage) {
    return null
  }

  return (
    <>
      {backgroundImage ? (
        <LazyLoadComponent>
          <Container style={{ backgroundImage: `url(${backgroundImage})` }} />
        </LazyLoadComponent>
      ) : null}
    </>
  )
})

const Container = styled.section`
  margin-bottom: 9.5625rem;
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 56.25rem;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1199px) {
    margin-bottom: 6.25rem;
    height: 66.667vh;
  }
  @media (max-width: 767px) {
    height: 50vh;
  }

  @supports (-webkit-touch-callout: none) {
    background-attachment: unset;
  }
`
