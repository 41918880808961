import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  images: ImageProps[]
  label?: string
  sectionID?: string
  subtitle?: string
  title?: string
}

export const Events = memo(function Events({
  description,
  images,
  label,
  sectionID,
  subtitle,
  title,
}: Props) {
  if (images.length < 1) {
    return null
  }
  return (
    <Container
      id={`section-${sectionID}`}
      tag="section"
      wrap
      row
      space="between"
      dial={2}
    >
      <Text>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </Text>

      {images
        ? images.map((image, index) => (
            <Wrapper key={index}>
              {image ? (
                <LazyLoadComponent>
                  <Image {...image} />
                </LazyLoadComponent>
              ) : null}
            </Wrapper>
          ))
        : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  position: relative;
  margin: 9.5625rem 0 9rem;
  padding: 0 9.722vw;
  height: 100%;
  align-items: flex-start;

  > div:nth-of-type(3),
  > div:nth-of-type(5) {
    top: -8.75rem;
  }

  @media (max-width: 1439px) {
    > div:nth-of-type(3),
    > div:nth-of-type(5) {
      top: -6rem;
    }
  }

  @media (max-width: 1199px) {
    margin: 6.25rem 0 5.25rem;

    > div:nth-of-type(3),
    > div:nth-of-type(5) {
      top: auto;
    }
  }

  @media (max-width: 991px) {
    padding: 0 1.875rem;
    margin-bottom: 1.875rem;
  }
`

const Text = styled.div`
  position: relative;
  z-index: 2;
  max-width: 41.875rem;
  padding-bottom: 3.4375rem;
  padding-right: 6.25vw;
  width: calc(50% - 0.5rem);

  @media (max-width: 1439px) {
    padding-right: 5vw;
  }

  @media (max-width: 1199px) {
    width: 100%;
    max-width: none;
    padding-bottom: 3.25rem;
    padding-right: 0;
    text-align: center;
  }
  @media (max-width: 767px) {
    padding-bottom: 1.875rem;
  }
`

const Label = styled.span`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  text-transform: uppercase;
  letter-spacing: 0.14rem;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 500;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.125rem;
  margin-top: 2.25rem;
  margin-bottom: 1.125rem;

  @media (max-width: 1199px) {
    margin-top: 1.5rem;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  @media (max-width: 767px) {
    margin-top: 1.125rem;
  }
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-style: italic;
  font-size: 1rem;
  line-height: 1.375rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 3.75rem;

  @media (max-width: 1439px) {
    margin-top: 1.875rem;
  }

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
    margin-left: 0;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    text-align: center;
  }
`
const Wrapper = styled.div`
  display: block;
  width: calc(50% - 0.5rem);
  height: 66.667vh;
  min-height: 37.5rem;
  overflow: hidden;
  position: relative;
  text-align: left;
  margin-bottom: 1rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1439px) {
    height: 50vh;
    min-height: 31.25rem;
  }

  @media (max-width: 1199px) {
    height: calc(50vw - 9.722vw - 0.5rem);
    min-height: auto;
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 75vw;
    margin-bottom: 1.875rem;
  }
  @media (max-width: 767px) {
    height: calc(100vw - 3.75rem);
  }
`
