import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  variant?: Variant
}

export const Scroll = memo(function Scroll({ variant = 'default' }: Props) {
  const scrollDown = () => {
    window.scroll({
      top: window.innerHeight + 1,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Container variant={variant}>
      <Button onClick={scrollDown} />
      <Text>scroll</Text>
    </Container>
  )
})

const Container = styled.div<ContainerProps>`
  cursor: pointer;
  text-align: center;
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  @media (max-width: 1199px) {
    top: auto;
    bottom: calc(4.861vw + 5.375rem);
  }

  ${({ variant }) => {
    switch (variant) {
      case 'large':
        return css`
          bottom: 2.5rem;

          @media (max-width: 1199px) {
            bottom: 6.125rem;
          }
        `
    }
  }}

  @media (max-width: 1199px) {
    bottom: 6.125rem;
  }
`

const Text = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  display: inline-block;
  margin-top: 1.25rem;
`

const Button = styled.div`
  height: 6rem;
  padding: 0 1.875rem 0;
  position: relative;

  cursor: pointer;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 0.0625rem;
    height: 100%;
  }
  &:before {
    animation: scrolldown 2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
    background: linear-gradient(
      to bottom,
      ${rgba(theme.colors.variants.neutralLight3, 1)} 50%,
      ${rgba(theme.colors.variants.neutralLight3, 0)} 50%
    );
    background-position: 0 -6rem;
    background-size: 100% 200%;
  }
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    opacity: 0.2;
  }

  @keyframes scrolldown {
    0% {
      background-position: 0 -6rem;
    }
    35% {
      background-position: 0 0;
    }
    100% {
      background-position: 0 6rem;
    }
  }

  @media (max-width: 1199px) {
    height: 3rem;

    @keyframes scrolldown {
      0% {
        background-position: 0 -3rem;
      }
      35% {
        background-position: 0 0;
      }
      100% {
        background-position: 0 3rem;
      }
    }
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'large'
