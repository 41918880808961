import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  backgroundImage?: string
  label?: string
  title?: string
}

export const MainParagraph = memo(function MainParagraph({
  backgroundImage,
  label,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container id="section-copy">
      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  padding: 7.5rem 9.722vw 4.6875rem;
  position: relative;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 3.75rem 9.722vw;
  }
  @media (max-width: 991px) {
    padding: 3.75rem 1.875rem;
  }
  @media (max-width: 767px) {
    padding: 2.8125rem 1.875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 6.25rem;
  line-height: 6.25rem;
  max-width: 53.75rem;
  margin: 3.75rem auto 0;
  text-align: center;

  @media (max-width: 1199px) {
    color: ${({ theme }) => theme.colors.variants.primaryDark};
    font-size: 3.125rem;
    line-height: 3.125rem;
  }
  @media (max-width: 1023px) {
    br {
      content: ' ';
      display: inline;
      padding: 0 0.1875rem;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      -webkit-text-fill-color: ${({ theme }) =>
        theme.colors.variants.primaryDark};
    }
  }
`

const Label = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.14rem;
  line-height: 1rem;
`
