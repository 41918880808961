import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  items: ItemProps[]
  label?: string
  sectionID?: string
  title?: string
}

export const Location = memo(function Location({
  cta,
  description,
  image,
  items,
  label,
  sectionID,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container dial={5} row wrap tag="section">
      {image ? (
        <Wrapper>
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        </Wrapper>
      ) : null}
      <LeftSide id={`section-${sectionID}`} dial={4}>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
      </LeftSide>
      <RightSide>
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        <Locations>
          <FadeInUp>
            {items.map((item: any, index) => (
              <Item key={index} {...item} />
            ))}
          </FadeInUp>
        </Locations>
        {cta ? (
          <FadeInUp>
            <CTA {...cta} />
          </FadeInUp>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  position: relative;
  margin: 2.75rem 0 7.5rem;

  @media (max-width: 1199px) {
    margin-bottom: 3.75rem;
  }
  @media (max-width: 767px) {
    margin-bottom: 2.5rem;
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  position: relative;
  z-index: 2;

  picture {
    width: 100%;
    height: 77.778vh;
    max-height: 43.75rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 1199px) {
    picture {
      width: 100%;
      height: 50vh;
    }
  }
`

const LeftSide = styled(FlexBox)`
  position: relative;
  z-index: 2;
  flex-direction: column;
  width: 50%;
  height: 28.125rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  position: relative;
  z-index: 2;
  padding-left: 9.722vw;
  padding-right: 12.833vw;

  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    padding: 3.75rem 1.875rem 1.875rem;
  }
`

const Label = styled.span`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  text-transform: uppercase;
  letter-spacing: 0.14rem;
  font-size: 0.875rem;
  line-height: 1rem;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.125rem;
  margin-top: 2.25rem;
  margin-bottom: 0.75rem;

  @media (max-width: 1199px) {
    margin-top: 1.5rem;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  @media (max-width: 767px) {
    margin-top: 1.125rem;
  }
`

const RightSide = styled.div`
  position: relative;
  width: 50%;
  padding: 5.6875rem 9vw 0 6.6vw;

  &:after {
    content: '';
    position: absolute;
    width: 20.833vw;
    height: 100%;
    top: calc(100% - 9.375rem);
    right: -3.472vw;
    background-image: url('./flower-dx.svg');
    background-repeat: no-repeat;
    z-index: 3;
  }

  @media (max-width: 1199px) {
    padding: 3.125rem 9vw 0 6.6vw;

    &:after {
      content: none;
    }
  }
  @media (max-width: 991px) {
    width: 100%;
    padding: 1.875rem 1.875rem 3.75rem;
  }
`

const Description = styled.div`
  position: relative;
  z-index: 2;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-left: 0;
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }
`

const Locations = styled.div`
  position: relative;
  z-index: 2;
`

const CTA = styled(Button)`
  position: relative;
  z-index: 2;
  margin-top: 3.75rem;

  @media (max-width: 574px) {
    text-align: center;
  }
`
