import styled from '@emotion/styled'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  className?: string
}

export const Line = memo(function Line({ className }: Props) {
  const [lineRef, isLineVisible] = useInView({ threshold: 0.5 })
  return (
    <Container
      className={isLineVisible ? 'line visible' : 'line'}
      ref={lineRef}
    />
  )
})

const Container = styled.div`
  position: relative;
  width: 0.0625rem;
  height: 48.889vh;

  &:before {
    content: '';
    width: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    position: absolute;
    top: 0;
    bottom: 100%;
    left: 0;
    transition: 0.6s 0.4s ease-in-out;
  }
  @media (max-width: 1199px) {
    height: 30.556vh;

    &:before {
      bottom: 0;
      transition: none;
    }
  }

  &.visible {
    &:before {
      bottom: 0;
    }
  }

  @media (max-width: 991px) {
    height: 15vh;
  }
`
